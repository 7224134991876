<template>
  <div class="card">
    <div class="card-header">
      <h3 class="title">Análises</h3>
      <p class="category">Análises mais recentes para sítio: <a style="color:blueviolet">Puro Peixe</a></p>
    </div>
    
    <div class="card-body all-icons">
      <div class="row" style="margin-left: 2vw;">
        <card class="card" style="width: 20rem;padding:0vw">
          
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('cdom')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_CDOM.png"></button>
          <p class="card-text"  style="text-align: center;">Msatéria Orgânica Colorida Dissolvida (CDOM).</p>
        </card>
        <div>

</div>
        <card class="card" style="width: 20rem;padding:0vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('tsi')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_TSI.png"></button>
          <p class="card-text" style="text-align: center;">Índice de Estado Trófico.</p>
        </card>
        <card class="card" style="width: 20rem;padding:0vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('do')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/do-1.png"></button>
          <p class="card-text"  style="text-align: center;">Oxigênio Dissolvido.</p>
        </card>
      </div>
      <div class="row" style="margin-left: 2vw;">
        <card class="card" style="width: 20rem;padding:0vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('chlorophylla')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_ClorofilaA.png"></button>
          <p class="card-text"  style="text-align: center;">Clorofila-a.</p>
        </card>
        <card class="card" style="width: 20rem;padding:0vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('fai')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_FAI.png"></button>
          <p class="card-text"  style="text-align: center;">Índice de Alga Flutuante.</p>
        </card>
        <card class="card" style="width: 20rem;padding:0vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('tsm')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_TSM.png"></button>
          <p class="card-text"  style="text-align: center;">Matéria Total Suspensa.</p>
        </card>
      </div>
      <div class="row" style="margin-left: 2vw;">
        <card class="card" style="width: 20rem;padding:0vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('temp')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_Temp-1.png"></button>
          <p class="card-text"  style="text-align: center;">Temperatura.</p>
        </card>
        <card class="card" style="width: 20rem;padding:0vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('tsi_class')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_TSIclass.png"></button>
          <p class="card-text" style="text-align: center;">Classe de Estado Trófico.</p>
        </card>
        <card class="card" style="width: 20rem;padding:0vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('secchi')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_Zsd.png"></button>
          <p class="card-text"  style="text-align: center;">Profundidade de Secchi.</p>
        </card>
      </div>
    </div>
    <div class="card-header">
      <p class="category">Análises mais recentes para sítio: <a style="color:blueviolet">Boa Esperança</a></p>
    </div>
    <div class="card-body all-icons">
      <div class="row" style="margin-left: 2vw;">
        <card class="card" style="width: 20rem;padding:1vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('do_be')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/do_be-1.png"></button>
          <p class="card-text"  style="text-align: center;">Oxigênio Dissolvido.</p>
        </card>
      </div>
    </div>
    <div class="card-header">
      <p class="category">Análises mais recentes para sítio: <a style="color:blueviolet">Querência</a></p>
    </div>
    <div class="card-body all-icons">
      <div class="row" style="margin-left: 2vw;">
        <card class="card" style="width: 20rem;padding:1vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('evi')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/Querencia_EVI.png"></button>
          <p class="card-text"  style="text-align: center;">EVI (Enhanced Vegetation Index).</p>
        </card>
        <card class="card" style="width: 20rem;padding:1vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('lai')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/Querencia_LAI.png"></button>
          <p class="card-text" style="text-align: center;">LAI (Leaf Area Index).</p>
        </card>
        <card class="card" style="width: 20rem;padding:1vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('ndmi')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/Querencia_NDMI.png"></button>
          <p class="card-text"  style="text-align: center;">NDMI (Normalized Difference Moisture Index).</p>
        </card>
      </div>
      <div class="row" style="margin-left: 2vw;">
        <card class="card" style="width: 20rem;padding:1vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('ndvi')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/Querencia_NDVI2.png"></button>
          <p class="card-text"  style="text-align: center;">NDVI (Normalized Difference Vegetation Index).</p>
        </card>
        <card class="card" style="width: 20rem;padding:1vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('cire')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/Querencia_CIRE.png"></button>
          <p class="card-text"  style="text-align: center;">CIRE (Red-edge Chlorophyll Index).</p>
        </card>
        <card class="card" style="width: 20rem;padding:1vw">
          <button style="background-color: transparent;border-color: transparent;cursor:pointer" @click="$router.push('ndwi')"><img slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/Querencia_NDWI.png"></button>
          <p class="card-text"  style="text-align: center;">NDWI (Normalized Difference Water Index).</p>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import modal from "@/components/Modal.vue"
export default {};
</script>
<style>
</style>
