<template>
    <div class="row"><h3>Profundidade de Secchi</h3>
        <card class="card">
            <img  slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_Zsd.png">
            <p class="card-text-bottom"  style="text-align: center;">A profundidade de Secchi é uma medida da opacidade de um corpo hídrico, e está diretamente relacionado com a presença de partículas na água.</p>
        </card>
</div>
    
  </template>
  <script>
  export default {
    name: 'fai',
    data() {
      return {};
    }
  };
  </script>
  <style></style>