<template>
  <div class="row">
    <card>
    <form @submit.prevent>
      <base-input label="Possui dúvidas? Mande uma mensagem que entraremos em contato para ajudar a resolvê-la."
                  type="email"
                  placeholder="-">
          <small slot="helperText" id="emailHelp" class="form-text text-muted">Seus dados estão sempre protegidos.</small>
      </base-input>
      <base-checkbox>
        Notificar-me por e-mail
     </base-checkbox>
     <base-button native-type="submit" type="primary">Enviar</base-button>
    </form>
</card>
  </div>
</template>
<script>
  export default {};
</script>
<style>
</style>
