<template>
    <div class="row"><h3>Índice de Alga Flutuante</h3>
        <card class="card">
            <img  slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_FAI.png">
            <p class="card-text-bottom"  style="text-align: center;">O índice FAI (Floating Algae Index, ou Índice de Alga Flutuante) indica a presença de algas e microalgas na água.</p>
        </card>
</div>
    
  </template>
  <script>
  export default {
    name: 'fai',
    data() {
      return {};
    }
  };
  </script>
  <style></style>
  