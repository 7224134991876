<template>
  <div class="row">
    <div class="col-md-8">
      <edit-profile-form :model="model">
      </edit-profile-form>
    </div>
    <div class="col-md-4">
      <user-card :user="user"></user-card>
    </div>
  </div>
</template>
<script>
  import EditProfileForm from './Profile/EditProfileForm';
  import UserCard from './Profile/UserCard'
  export default {
    components: {
      EditProfileForm,
      UserCard
    },
    data() {
      return {
        model: {
          company: 'Quasar Space',
          email: 'contato@quasarspace.com',
          username: 'quasarl23',
          firstName: 'Quasar',
          lastName: 'Space',
          address: 'Praça Marechal-do-Ar Eduardo Gomes, 50 ',
          city: 'São José dos Campos',
          country: 'Brasil',
          about: 'Empresa de sensoriamento remoto e soluções espaciais para o agronegócio.'
        },
        user: {
          fullName: 'Quasar Space',
          title: 'CEO/Co-Founder',
          description: `A Quasar Space surgiu com o propósito de trazer soluções espaciais para a otimização de produção agrícola.`,
        }
      }
    }
  }
</script>
<style>
</style>
