<template>
    <div class="row">
      <div class="col-12">
        <card >
          <div ref="map-root"
            style="width: 100%; height: 100vh">
          </div>
        </card>
        
      </div>

      <div class="col-12">
        <card class="card-plain">
          <div class="table-full-width table-responsive">
            <base-table :title="table2.title" :sub-title="table2.subTitle" :data="table2.data"
                         :columns="table2.columns">

            </base-table>
          </div>
        </card>
      </div>

    </div>
</template>
<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { BaseTable } from "@/components";


const tableColumns = ["Nome", "Coordenadas", "Descrição"];
const tableData = [
{
          "id": 1,
          "nome": "Puro Peixe",
          "coordenadas": "[-20.044,-50.933]",
          "descrição": "Aquicultura"
        },
        {"id": 2,
      "nome":"Boa Esperança",
    "coordenadas":"[-53.271,-25.612]",
  "descrição":"Aquicultura"},
  {"id": 3,
      "nome":"Querência",
    "coordenadas":"[-12.117,-52.860]",
  "descrição":"Fazenda"}
];

export default {
  components: {
    BaseTable
  },
  data() {
    return {
      map: null,
      table2: {
        title: "Table on Plain Background",
        columns: [...tableColumns],
        data: [...tableData]
      }
    };
  },
  mounted() {
    this.map = L.map(this.$refs['map-root']).setView([-20.044,-50.933], 12);


    var OpenStreetMap_Mapnik = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);

    var cartoLight = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    baseLayer: true
    });
    //use a mix of renderers
    var customPane = this.map.createPane("customPane");
    var canvasRenderer = L.canvas({ pane: "customPane" });
    customPane.style.zIndex = 399; // put just behind the standard overlay pane which is at 400

    L.marker([-20.04405168135771,-50.933795227068416]).bindPopup("Batelão").addTo(this.map);
    L.marker([-20.041955238728764,-50.931821121233455]).bindPopup("6x6").addTo(this.map);
    L.marker([-20.04198547608097,-50.93346263315057]).bindPopup("Porto").addTo(this.map);
    L.marker([-20.0431748073166,-50.93011523629998]).bindPopup("Zippy").addTo(this.map);
    L.marker([-12.117085385683376,-52.86049896230106]).bindPopup("Fazenda - Querência").addTo(this.map);
    L.marker([-25.606011570655998, -53.26242270444017]).bindPopup("Boa Esperança").addTo(this.map);

    var geoj = {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "coordinates": [
              [
                [
                  -50.97105293773919,
                  -20.027483539693506
                ],
                [
                  -50.97105293773919,
                  -20.082846943959126
                ],
                [
                  -50.882045755618805,
                  -20.082846943959126
                ],
                [
                  -50.882045755618805,
                  -20.027483539693506
                ],
                [
                  -50.97105293773919,
                  -20.027483539693506
                ]
              ]
            ],
            "type": "Polygon"
          }
        }
      ]
    }

    var geoj2 = {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "coordinates": [
              [
                [
                  -53.02949896230106,
                  -11.857085385683376
                ],
                [
                  -53.02949896230106,
                  -12.292241967520013
                ],
                [
                  -52.70568823649819,
                  -12.292241967520013
                ],
                [
                  -52.70568823649819,
                  -11.857085385683376
                ],
                [
                  -53.02949896230106,
                  -11.857085385683376
                ]
              ]
            ],
            "type": "Polygon"
          }
        }
      ]
    }

    
    var gjLayer = L.geoJSON(geoj).bindPopup("Aquicultura - Puro Peixe");
    var gjLayer2 = L.geoJSON(geoj2).bindPopup("Fazenda - Querência");
    // Add the WMS layer to the map
    this.map.addLayer(gjLayer);
    this.map.addLayer(gjLayer2);

    this.map.addLayer(cartoLight);
    
    //var driveUrl = 'https://drive.google.com/uc?id=1tcoUV3rjyKxIMnsYWlzMUm-O3ZAYX4f9';

    var layerControl = L.control.layers({
    "OpenStreetMap" : OpenStreetMap_Mapnik,
    "ESRI" : cartoLight}, {
    
    "Puro Peixe" : gjLayer,
    "Querência":gjLayer2
    });
    layerControl.addTo(this.map);
  },
  onBeforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  },
};
</script>
<style>
</style>
