<template>
    <div class="row"><h3>Temperatura</h3>
        <card class="card">
            <img  slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_Temp-1.png">
            <p class="card-text-bottom"  style="text-align: center;">Através de sensores dedicados é possível obter a temperatura da superfície terrestre por satélites.</p>
        </card>
</div>
    
  </template>
  <script>
  export default {
    name: 'fai',
    data() {
      return {};
    }
  };
  </script>
  <style></style>