<template>
    <div ref="map-root"
         style="width: 100%; height: 100vh">
    </div>
  </template>
  
  <script>
    import "leaflet/dist/leaflet.css";
    import L from "leaflet";

export default {
  name: "LeafletMap",
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    this.map = L.map(this.$refs['map-root']).setView([-20.044,-50.933], 12);
    var OpenStreetMap_Mapnik = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);

    var cartoLight = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    baseLayer: true
    });
    //use a mix of renderers
    var customPane = this.map.createPane("customPane");
    var canvasRenderer = L.canvas({ pane: "customPane" });
    customPane.style.zIndex = 399; // put just behind the standard overlay pane which is at 400

    L.marker([-20.04405168135771,-50.933795227068416]).bindPopup("Batelão").addTo(this.map);
    L.marker([-20.041955238728764,-50.931821121233455]).bindPopup("6x6").addTo(this.map);
    L.marker([-20.04198547608097,-50.93346263315057]).bindPopup("Porto").addTo(this.map);
    L.marker([-20.0431748073166,-50.93011523629998]).bindPopup("Zippy").addTo(this.map);
    L.marker([-25.606011570655998, -53.26242270444017]).bindPopup("Boa Esperança").addTo(this.map);
    

    var mywms = L.tileLayer.wms("http://localhost:8080/geoserver/wms", {
        layers: 'PortalQ:dissolved_oxygen2',
        format: 'image/png',
        transparent: true,
        version: '1.1.0',
        styles: 'diss',
        attribution: "myattribution"
    });

    var mywms2 = L.tileLayer.wms("http://localhost:8080/geoserver/wms", {
        layers: '	PortalQ:bathymetry2',
        format: 'image/png',
        transparent: true,
        version: '1.1.0',
        styles: 'bath',
        attribution: "myattribution"
    });

    var geoj = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "coordinates": [
          [
            [
              -50.97105293773919,
              -20.027483539693506
            ],
            [
              -50.97105293773919,
              -20.082846943959126
            ],
            [
              -50.882045755618805,
              -20.082846943959126
            ],
            [
              -50.882045755618805,
              -20.027483539693506
            ],
            [
              -50.97105293773919,
              -20.027483539693506
            ]
          ]
        ],
        "type": "Polygon"
      }
    }
  ]
}
    var boaEsp = {"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Polygon","coordinates":[[[-53.271076,-25.61244999999998],[-53.250023,-25.61244999999998],[-53.250023,-25.596852],[-53.271076,-25.596852],[-53.271076,-25.61244999999998]]]}}]};
    var beLayer = L.geoJSON(boaEsp);
    var gjLayer = L.geoJSON(geoj);
    
    // Add the WMS layer to the map
    this.map.addLayer(mywms);
    // Add the WMS layer to the map
    this.map.addLayer(mywms2);
    this.map.addLayer(cartoLight);
    //var driveUrl = 'https://drive.google.com/uc?id=1tcoUV3rjyKxIMnsYWlzMUm-O3ZAYX4f9';
    var driveURL = 'https://quasarspace.com.br/wp-content/uploads/2023/02/dissolved_oxygen2.tif';

    var layerControl = L.control.layers({
    "OpenStreetMap" : OpenStreetMap_Mapnik,
    "ESRI Satellite Imagery" : cartoLight}, {
    
    "Oxigênio Dissolvido'": mywms,
    "Batimetria":mywms2

    // Add the layer control to the map
    
    });
    layerControl.addTo(this.map);

  },
  onBeforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  },
};
  </script>