<template>
    <div id="app">
      <div class="cell cell-map">
        <MapContainer></MapContainer>
      </div>
    </div>
  </template>
  
  <script>
  import MapContainer from '@/components/MapContainer.vue'
    export default {
      name: 'Map',
      components:{
        MapContainer
      }
    }
  </script>
  
  <style>
    html, body {
      height: 100vh;
      margin: 0;
    }
  
    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      height: 100vh;
      display: grid;
      grid-template-columns: 100%;
      grid-auto-rows: 1fr;
      grid-gap: 1rem;
      padding: 1rem;
      box-sizing: border-box;
    }
  
    .cell {
      border-radius: 4px;
      background-color: lightgrey;
    }
  
    .cell-map {
      grid-column: 1;
      grid-column-end:3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  </style>