<template>
  <div class="row">
    <div class="col-md-6">
      <card>
        <h4 slot="header">Avisos gerais</h4>
        <base-alert type="info" dismissible>
          <span>Período de teste - modelo de oxigênio</span>
        </base-alert>
        <base-alert type="info" dismissible>
          <span>Reestruturação da plataforma - sistema pode estar instável</span>
        </base-alert>
      </card>
    </div>
    <div class="col-md-6">
      <card>
        <h4 slot="header">Notificações</h4>
        <base-alert type="primary" dismissible>
          <span><b> Lembrete - </b> Realizar a medição de oxigênio durante o horário de passagem dos satélites</span>
        </base-alert>
        <base-alert type="danger" dismissible>
          <span><b> Urgente - </b> Calibrar o sensor de oxigênio do batelão</span>
        </base-alert>
      </card>
    </div>
    
  </div>
</template>
<script>
  import NotificationTemplate from './Notifications/NotificationTemplate';
  import { BaseAlert } from '@/components';

  export default {
    components: {
      BaseAlert
    },
    data() {
      return {
        type: ["", "info", "success", "warning", "danger"],
        notifications: {
          topCenter: false
        }
      };
    },
    methods: {
      notifyVue(verticalAlign, horizontalAlign) {
        const color = Math.floor(Math.random() * 4 + 1);
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: this.type[color],
          timeout: 0
        });
      }
    }
  };
</script>
<style>
</style>
