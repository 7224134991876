<template>
    <div class="row"><h3>Classificação de Estado Trófico</h3>
        <card class="card">
            <img  slot="image" class="card-img-top" src="https://quasarspace.com.br/wp-content/uploads/2023/02/PuroPeixe_TSIclass.png">
            <p class="card-text-bottom"  style="text-align: center;">A classificação por índice de TSI é realizada da seguinte forma:</p>
            <ol class="card-text" style="padding-top: 1vh;">
                <ul style="color: white;">
                    0-59: Oligotrófico (Qualidade alta)
                </ul>
                <ul style="color: white;">
                    60-69: Eutrófico (Qualidade média)
                </ul>
                <ul style="color: white;">
                    70-100: Hipereutrófico (Qualidade baixa)
                </ul>
            </ol>
        </card>
</div>
    
  </template>
  <script>
  export default {
    name: 'fai',
    data() {
      return {};
    }
  };
  </script>
  <style></style>
  